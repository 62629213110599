import '@babel/polyfill';
require('./bootstrap')

Vue.component('loginform', require('./components/auth/LoginForm.vue').default)
Vue.component('registrationform', require('./components/RegistrationForm.vue').default)

const app = new Vue({
  el: '#public'
})

