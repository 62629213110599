<template>
	<form method="post" @submit.prevent="submit" @keydown="clearErrors($event.target.name)">
		<div id="form-title" class="text-center big-font">
			<img id="logo" src="img/logo.png">
		</div>
		<div id="form-wrapper">
			<div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('email') }">
				<input type="email" class="form-control" placeholder="E-mail" name="email" value="" v-model="form.email" focused autofocus/>
				<span class="glyphicon form-control-feedback" :class="[icon]"></span>
				<transition name="fade">
					<span class="help-block" v-if="form.errors.has('email')" v-text="form.errors.get('email')" id="validation_error_email"></span>
				</transition>
			</div>

<!--   <div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('username') }" v-else>
<input type="text" class="form-control" :placeholder="placeholder" :name="name" v-model="form.username" @change="adddomain" autofocus/>
<span class="glyphicon form-control-feedback" :class="[icon]"></span>
<transition name="fade">
<span class="help-block" v-if="form.errors.has('username')" v-text="form.errors.get('username')" id="validation_error_username"></span>
</transition>
</div> -->


			<div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('password') }">
				<input type="password" class="form-control" placeholder="Mot de passe" name="password" v-model="form.password"/>
				<span class="glyphicon glyphicon-lock form-control-feedback"></span>
				<transition name="fade">
					<span class="help-block" v-if="form.errors.has('password')" v-text="form.errors.get('password')" id="validation_error_password"></span>
				</transition>
			</div>
			<div class="row">
				<div class="col-xs-6 col-xs-offset-3">
					<button type="submit" class="btn btn-primary btn-block btn-flat"  :disabled="isDisabled">Connexion <i v-if="form.submitting" class="fa fa-refresh fa-spin"> </i> 
          </button>

				</div>
			</div>
		</div>
	</form>
</template>

<!-- <style src="./fade.css"></style> -->

<script>

  import Form from 'acacha-forms'
/*  import initialitzeIcheck from './InitializeIcheck'*/
  import redirect from './redirect'

  export default {
    mixins: [/*initialitzeIcheck, */redirect],
    data: function () {
      let form = new Form({ email: '', password: '', })
      if (this.name === 'email') {
        form = new Form({ email: '', password: '', remember: '' })
      }
      return {
        form: form,
      }
    },
    // props: {
    //   name: {
    //     type: String,
    //     required: true
    //   },
    //   domain: {
    //     type: String,
    //     required: false
    //   }
    // },
    computed: {
      placeholder: function () {
        if (this.name === 'email') return this.trans('adminlte_lang_message.email')
        return this.trans('adminlte_lang_message.username')
      },
      type: function () {
        if (this.name === 'email') return 'email'
        return 'text'
      },
      icon: function () {
        if (this.name === 'email') return 'glyphicon-envelope'
        return 'glyphicon-user'
      },
      isDisabled: function () {
        return (this.form.errors.any() || this.form.submitting)
      },
    },
    methods: {
      submit () {
        this.form.post('./login')
          .then(response => {
            // console.log(response);
            var component = this;
            component.redirect('board/registrations')
          })
          .catch(error => {
            console.log(this.trans('adminlte_lang_message.loginerror') + ':' + error)
          })
      },
      clearErrors (name) {
        if (name === 'password') {
          this.form.errors.clear('password')
          name = this.name
        }
        this.form.errors.clear(name)
      }
    },
    mounted () {
      /*this.initialitzeICheck('remember')*/
    },
  }

</script>
