<template>
	<form ref="form" method="post" action="registration_confirmed" @submit.prevent="submit" @keydown="clearErrors($event.target.name)" @change="clearErrors($event.target.name)"  class="" id="registration-form" autocomplete="off">

		<div class="text-right" style="margin-bottom: 15px"><span class="blue_hsm">*</span> : champs obligatoires</div>

<!-- 		<div class="alert alert-success text-center" v-show="form.succeeded" id="result">
			<i class="fa fa-refresh fa-spin"></i>
		</div> -->

		<input type="hidden" name="_token" :value="csrf">
		<input type="hidden" name="id" v-model="form.id">
		<div class="row">
			<div class="panel panel-default no-radius">
				<div class="panel-heading">Informations personnelles</div>
				<div class="panel-body">
					<label class=" control-label" for="lastname">Nom d'usage : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('lastname') }">
						<div class="">
							<input type="text" class="form-control" placeholder="Nom d'usage" name="lastname" value="" v-model="form.lastname" required>
							<span class="glyphicon glyphicon-user form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('lastname')" v-text="form.errors.get('lastname')"></span>
							</transition>
						</div>
					</div>


					<label class=" control-label" for="birthname">Nom de naissance : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback  " :class="{ 'has-error': form.errors.has('birthname') }">
						<div class="">
							<input type="text" class="form-control" placeholder="Nom de naissance" name="birthname" value="" v-model="form.birthname" required>
							<span class="glyphicon glyphicon-user form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('birthname')" v-text="form.errors.get('birthname')"></span>
							</transition>
						</div>
					</div>


					<label class=" control-label" for="firstname">Prénom : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback  " :class="{ 'has-error': form.errors.has('firstname') }">
						<div class="">
							<input type="text" class="form-control" placeholder="Prénom" name="firstname" value="" v-model="form.firstname" required />
							<span class="glyphicon glyphicon-user form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('firstname')" v-text="form.errors.get('firstname')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="birthdate">Date de naissance : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('birthdate') }">
						<div class="">
							<datepicker id="birthdate" name="birthdate" placeholder="Date de naissance" :initialView="'year'" v-model="form.birthdate" :disabledDates="disabledDatesFromToday" :format="format" :language="fr" required ></datepicker>
							<span class="glyphicon glyphicon-calendar form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('birthdate')" v-text="form.errors.get('birthdate')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="email">Adresse e-mail : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('email') }">
						<div class="">
							<input type="email" class="form-control" placeholder="Email" name="email" value="" v-model="form.email" required />
							<span class="glyphicon glyphicon-envelope form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="phone">Téléphone : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('phone') }">
						<div class="">
							<vue-tel-input v-model="form.phone"
									@onInput="onPatientPhoneInput"
									placeholder="N° de téléphone"
									:preferredCountries="['fr']"
									:disabledFetchingCountry=true
									defaultCountry="fr"
									required>
							</vue-tel-input>
							<span class="glyphicon glyphicon-earphone form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('phone')" v-text="form.errors.get('phone')"></span>
							</transition>
						</div>
					</div>

				</div>
			</div>

			<div class="panel panel-default no-radius">
				<div class="panel-heading">Accouchement</div>
				<div class="panel-body">
					<label class=" control-label" for="echo">La date du début de votre grossesse a été fixée par une échographie, un médecin ou une sage-femme ? <span class="blue_hsm">*</span></label>
					<div class=" form-group has-feedback" :class="{ 'has-error': form.errors.has('echo') }">
						<label for="echo-oui" class="radio-inline  ">
							<input name="echo" id="echo-oui" value="1" type="radio" checked v-model="form.echo" required>
							Oui
						</label>
						<label for="echo-non" class="radio-inline  " style="margin-left:15px">
							<input name="echo" id="echo-non" value="0" type="radio" v-model="form.echo">
							Non
						</label>
						<transition name="fade">
							<span class="help-block" v-if="form.errors.has('echo')" v-text="form.errors.get('echo')"></span>
						</transition>
					</div>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('dpa_echo') }">
						<div v-if="form.echo==1" class="">
							<datepicker id="dpa_echo" name="dpa_echo" placeholder="Date de début de grossesse" value="" v-model="form.dpa_echo" :disabledDates="disabledDatesToOneYearFromToday" :format="format" :language="fr" required ></datepicker>
							<span class="glyphicon glyphicon-calendar form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('dpa_echo')" v-text="form.errors.get('dpa_echo')"></span>
							</transition>
						</div>
					</div>

					<div v-if="form.echo==0">
						<label class=" control-label" for="last">Connaissez-vous la date du 1er jour de vos dernières règles ? <span class="blue_hsm">*</span></label>
						<div class=" form-group has-feedback" :class="{ 'has-error': form.errors.has('echo') }">
							<label for="last-oui" class="radio-inline">
								<input name="last" id="last-oui" value="1" type="radio" checked v-model="form.last" required>
								Oui
							</label>
							<label for="last-non" class="radio-inline  " style="margin-left:15px">
								<input name="last" id="last-non" value="0" type="radio" v-model="form.last">
								Non
							</label>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('last')" v-text="form.errors.get('last')"></span>
							</transition>
						</div>
					</div>
					<div v-if="form.last==1&&form.echo==0" class="form-group has-feedback " :class="{ 'has-error': form.errors.has('dpa_last') }">
						<div class="">
							<datepicker id="dpa_last" name="dpa_last" placeholder="Date du 1er jour des dernières règles" value="" v-model="form.dpa_last" :disabledDates="disabledDatesToOneYearFromToday" :format="format" :language="fr" required ></datepicker>
							<span class="glyphicon glyphicon-calendar form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('dpa_last')" v-text="form.errors.get('dpa_last')"></span>
							</transition>
						</div>
					</div>

					<div v-if="form.echo==0&&form.last==0">
						<label class=" control-label" for="dpa">Quelle est la date prévue de votre accouchement ? <span class="blue_hsm">*</span></label>
						<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('dpa') }">
							<div class="">
								<datepicker id="dpa" name="dpa" placeholder="Date prévue d'accouchement" value="" v-model="form.dpa" :disabledDates="disabledDatesToTodayFromOneYear" :format="format" :language="fr" required ></datepicker>
								<span class="glyphicon glyphicon-calendar form-control-feedback"></span>
								<transition name="fade">
									<span class="help-block" v-if="form.errors.has('dpa')" v-text="form.errors.get('dpa')"></span>
								</transition>
							</div>
						</div>
					</div>

					<label class=" control-label" for="doctor">Médecin traitant : <span class="blue_hsm">*</span></label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('doctor') }">
						<div class="">
							<input id="doctor" name="doctor" placeholder="Médecin traitant" class="form-control input-md" type="text" v-model="form.doctor" required>
							<span class="glyphicon glyphicon-briefcase form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('doctor')" v-text="form.errors.get('doctor')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="already_hsm">Avez-vous déjà accouché à la maternité des Hôpitaux Paris Est Val-de-Marne ? <span class="blue_hsm">*</span></label>

					<div class=" form-group has-feedback" style="padding-bottom: 15px;" :class="{ 'has-error': form.errors.has('already_hsm') }">
						<label for="type-patient" class="radio-inline  ">
							<input name="already_hsm" id="type-patient" value="1" type="radio" checked v-model="form.already_hsm" required>
							Oui
						</label>
						<label for="type-praticien" class="radio-inline  " style="margin-left:15px">
							<input name="already_hsm" id="type-praticien" value="0" type="radio" v-model="form.already_hsm">
							Non
						</label>
						<transition name="fade">
							<span class="help-block" v-if="form.errors.has('already_hsm')" v-text="form.errors.get('already_hsm')"></span>
						</transition>
					</div>
				</div>
			</div>
		</div>


		<div class="row">
			<div class="panel panel-default no-radius">
				<div class="panel-heading">Contacts</div>
				<div class="panel-body">
					<label class=" control-label" for="ice1_name">Nom de la personne à prévenir :</label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('ice1_name') }">
						<div class="">
							<input id="ice1_name" name="ice1_name" placeholder="Personne à prévenir" class="form-control input-md" type="text" v-model="form.ice1_name">
							<span class="glyphicon glyphicon-user form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('ice1_name')" v-text="form.errors.get('ice1_name')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="ice1_phone">Téléphone de la personne à prévenir :</label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('ice1_phone') }">
						<div class="">
							<vue-tel-input v-model="form.ice1_phone"
									@onInput="onIce1PhoneInput"
									placeholder="N° de téléphone"
									:preferredCountries="['fr']"
									:disabledFetchingCountry=true
									defaultCountry="fr">
							</vue-tel-input>
							<span class="glyphicon glyphicon-earphone form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('ice1_phone')" v-text="form.errors.get('ice1_phone')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="ice2_name">Si différent, nom de la personne de confiance :</label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('ice2_name') }">
						<div class="">
							<input id="ice2_name" name="ice2_name" placeholder="Personne de confiance" class="form-control input-md" type="text" v-model="form.ice2_name">
							<span class="glyphicon glyphicon-user form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('ice2_name')" v-text="form.errors.get('ice2_name')"></span>
							</transition>
						</div>
					</div>

					<label class=" control-label" for="ice2_phone">Téléphone de la personne de confiance :</label>
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('ice2_phone') }">
						<div class="">
							<vue-tel-input v-model="form.ice2_phone"
									@onInput="onIce2PhoneInput"
									placeholder="N° de téléphone"
									:preferredCountries="['fr']"
									:disabledFetchingCountry=true
									defaultCountry="fr">
							</vue-tel-input>
							<span class="glyphicon glyphicon-earphone form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('ice2_phone')" v-text="form.errors.get('ice2_phone')"></span>
							</transition>
						</div>
					</div>
				</div>

			</div>

			<div class="panel panel-default no-radius">
				<div class="panel-heading">Précisions à apporter</div>
				<div class="panel-body">
					<div class="form-group has-feedback " :class="{ 'has-error': form.errors.has('precisions') }">
						<div class="">
							<textarea class="form-control input-md" rows="10" id="precisions" name="precisions" style="resize: none" v-model="form.precisions"></textarea>
							<span class="glyphicon glyphicon-pencil form-control-feedback"></span>
							<transition name="fade">
								<span class="help-block" v-if="form.errors.has('precisions')" v-text="form.errors.get('precisions')"></span>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>
 		<input type="text" name="website" value="" v-model="form.website" style="display:none !important" tabindex="-1" autocomplete="off">
 		<input type="datetime" name="hour" value ="" v-model="form.hour" style="display:none !important" tabindex="-1" autocomplete="off">

		<div class="recueil">En cliquant sur ''valider'' je confirme avoir lu et j'accepte sans réserve l'application de la Politique de Confidentialité des hôpitaux Paris Est Val-de-Marne ainsi que l'utilisation de mes données personnelles dans le cadre de cette Politique de Confidentialité.</div>
		<br>
		<div id="submit-wrapper">
			<button type="submit" class="btn btn-primary btn-lg btn-flat" :disabled="form.errors.any()||form.submitting"> Valider <i v-if="form.submitting" class="fa fa-refresh fa-spin"> </i> </button>
		</div>
	</form>

</template>

<script>
import Form from "acacha-forms";
import initialitzeIcheck from "./auth/InitializeIcheck";
import redirect from "./auth/redirect";
import Inputmask from "inputmask";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
	mixins: [initialitzeIcheck, redirect],
	props: ["api_url"],
	components: {
	Datepicker
	},
	data: function() {
		return {
			disabledDatesFromToday: {
				from: new Date()
			},
			disabledDatesToToday: {
				to: new Date()
			},
			disabledDatesToOneYearFromToday: {
				to: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
				from: new Date()
			},
			disabledDatesToTodayFromOneYear: {
				from: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
				to: new Date()
			},
			format: "dd/MM/yyyy",
			fr: fr,
			csrf: document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute("content"),
			form: new Form({
				id: "",
				lastname: "",
				birthname: "",
				birthdate: "",
				firstname: "",
				email: "",
				phone: "",
				phone_country: "",
				dpa: "",
				dpa_echo: "",
				dpa_last: "",
				doctor: "",
				precisions: "",
				already_hsm: "",
				ice1_name: "",
				ice1_phone: "",
				ice1_phone_country: "",
				ice2_name: "",
				ice2_phone: "",
				ice2_phone_country: "",
				echo: "1",
				last: "",
				website: "",
				hour: ""
			})
		};
	},
	watch: {
		"form.echo": function(value) {
			if (value == 0) {
				this.form.dpa_echo = "";
			} else {
				this.form.last = 0;
			}
		},
		"form.last": function(value) {
			if (value == 0) {
				this.form.dpa_last = "";
			} else {
				this.form.dpa = "";
			}
		}
	},

	methods: {
		submit() {
			var vm = this;
			this.form
				.post(this.api_url)
				.then(response => {
					this.form.id = response.data;
					this.$nextTick(function () {
        				this.$refs.form.submit();
      				})
				})
				.catch(error => {
					$('html, body').animate({
                    	scrollTop: $("#registration-form").offset().top
                	}, "slow");
					console.log(error);
				});
		},
		clearErrors(name) {
			if (name === "dpa" || name === "dpa_last" || name === "dpa_echo") {
				this.form.errors.clear("dpa");
				this.form.errors.clear("dpa_echo");
				this.form.errors.clear("dpa_last");
			}
			this.form.errors.clear(name);
			this.form.errors.clear("hour");
		},
		onPatientPhoneInput({ number, isValid, country }) {
			this.form.phone_country = country.iso2;
		},
		onIce1PhoneInput({ number, isValid, country }) {
			this.form.ice1_phone_country = country.iso2;
		},
		onIce2PhoneInput({ number, isValid, country }) {
			this.form.ice2_phone_country = country.iso2;
		}
	},
	mounted() {
		this.form.hour = moment().format("YYYY-MM-DD HH:mm:ss");
		Inputmask({ greedy: false }).mask(document.querySelectorAll("input"));
	}
};
</script>

<style>
.recueil{
	text-align: center;
}
</style>
